<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #icon>
      <svg-icon :name="isPublic ? 'public' : 'private'" class="plain-btn__icon" />
    </template>
    <template #name>
      <div class="tariff-table-item__server">
        <router-link
          :to="{ name: 'Storage', params: { storageid: item.name, id: id } }"
          class="tariffs-table-item__link medium-text"
        >
          {{ item.name }}
        </router-link>
      </div>
    </template>
    <template #type> Standard </template>
    <!--    <template #volume> {{ size(item) }}</template>-->
    <template #volume> {{ size(item) }}</template>
    <template #items>
      {{ item.count }}
    </template>
    <template #delete>
      <div class="buttons-actions">
        <Button
          v-if="multipartUploads[item.name]"
          icon="pi pi-info-circle"
          v-tooltip.left="'Инфо'"
          icon-pos="right"
          class="p-button-rounded p-button-text p-button-danger"
          @click="viewMultipartUploads(item)"
        ></Button>
        <Button
          :icon="loading ? 'pi pi-spin pi-spinner' : 'pi pi-sync'"
          v-tooltip.left="'Обновить'"
          icon-pos="right"
          class="p-button-rounded p-button-text p-button-primary"
          @click="getMultipartUploads(item)"
        ></Button>
        <!--        :disabled="isDisabledButtons || selectedObjects.length > 0"-->
        <!--        @click="deleteObject(slotProps.data.key)"-->
        <Button
          icon="pi pi-trash"
          v-tooltip.left="'Удалить'"
          :disabled="!isEmpty"
          icon-pos="right"
          class="p-button-rounded p-button-text p-button-danger"
          @click="deleteStorage(item)"
        ></Button>
      </div>
      <!--      <plain-button-->
      <!--        icon="delete"-->
      <!--        color="del"-->
      <!--        :disabled="!isEmpty"-->
      <!--        class="tariffs-table-item__more"-->
      <!--        @click="deleteStorage(item)"-->
      <!--      />-->
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import { objectSizeParser } from '@/utils/parsers/objectSizeParser';
import deleteStorage from '@/mixins/deleteStorage';
import Button from 'primevue/button';
import { format } from 'date-fns';
export default {
  name: 'TariffsTableStorageRow',
  components: {
    BaseTableRow,
    Button,
  },
  mixins: [deleteStorage],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timerCnt: 0,
      timers: null,
      loading: false,
    };
  },
  computed: {
    multipartUploads() {
      return this.$store.state.moduleStack.multipartUploads;
    },
    storageSize() {
      return this.item.objects.reduce((acc, obj) => {
        // acc += obj.actualSize;
        acc += obj?.size ? obj.size : 0;
        return acc;
      }, 0);
    },
    isEmpty() {
      return this.item.count === 0;
    },
    // s3keys() {
    //   return this.$store.getters('moduleStack/getS3Key');
    // },
    isPublic() {
      return this.item.policy.IsPublic;
    },
  },
  // watch: {
  //   item: {
  //     handler: function (event) {
  // console.log('item', event);
  // },
  // deep: true,
  // immediate: false,
  // },
  // },
  async mounted() {},
  methods: {
    viewMultipartUploads(item) {
      const text = [];
      console.log(this.multipartUploads[item.name]);
      this.multipartUploads[item.name].forEach(i => {
        if (i.Key)
          text.push({
            name: i.Key,
          });
        if (i.Initiated)
          text.push({
            date: format(i.Initiated, 'dd.MM.yyyy, HH:mm'),
          });
      });
      console.log(text);

      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text: text,
      });
    },
    async getMultipartUploads(item) {
      this.$emit('update-row', true);
      this.loading = true;
      // console.log(item.name);
      // const params = {
      //   name: this.tariff.name,
      //   policy: null,
      // };
      await this.$store
        .dispatch('moduleStack/ListMultipartUploads', item.name)
        // .then(data => console.log(data))
        .finally(() => {
          this.$emit('update-row', false);
          setTimeout(() => (this.loading = false), 300);
          // this.loading = false;
        });
    },
    size(item) {
      return objectSizeParser(item.size);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "delete": "Удалить контейнер",
    "off": "Выключен",
    "specs": {
      "id": "ID:",
      "ip": "IP:",
      "disc": "Disc"
    },
    "gb": "{num} ГБ"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.buttons-actions {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  gap: 0.15rem;
}
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
  &__sub{
    margin-top: 0.5rem;
  }
  &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
</style>
